"use client";
import { Alignment, Fit, Layout, useRive, useStateMachineInput } from "@rive-app/react-canvas";
import { useIntersectionObserver } from "usehooks-ts";
import { useEffect } from "react";

const PhoneAnimation = () => {
  const { rive, RiveComponent } = useRive({
    src: "/rive/phone_mockup.riv",
    stateMachines: "Reveal",
    autoplay: true,
    isTouchScrollEnabled: true,
    layout: new Layout({
      fit: Fit.Contain,
      alignment: Alignment.Center,
    }),
  });

  const scroll = useStateMachineInput(rive, "Reveal", "scrollPosition");
  const { ref, entry } = useIntersectionObserver({
    threshold: [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0],
  });
  useEffect(() => {
    if (scroll && entry) {
      scroll.value = entry.intersectionRatio * 40;
    }
  }, [entry, scroll]);
  return (
    <div className="relative h-full w-full">
      <div ref={ref} className="absolute left-1/2 top-[25%] h-[50%] w-4" />
      <RiveComponent />
    </div>
  );
};

export default PhoneAnimation;
