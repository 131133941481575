"use client";

import {
  Alignment,
  EventType,
  Fit,
  Layout,
  type RiveEventPayload,
  useRive,
  useStateMachineInput,
} from "@rive-app/react-canvas";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import type { ProjectDocumentRedisTicker, TrialFileUpload } from "@/types";
import { useInterval } from "usehooks-ts";
import { getMixpanelDistinctId, getRequest, mixpanelTrack, postRequestForFile } from "@/service/utils";
import { isProcessingDone, toPercentageValue } from "@/utils";
import { QUERY_PARAMS, QUINO_APP_URL } from "@/constants/landing";
import { UPLOAD_FILE } from "@/constants/core";

const TOTAL_PROGRESS = 100;
const MOCK_UPLOAD_LIMIT = 15;
const REST_OF_PROGRESS = TOTAL_PROGRESS - MOCK_UPLOAD_LIMIT;
const POLL_ERROR_LIMIT = 10;

const LandingCta = () => {
  const ref = useRef<HTMLDivElement | null>(null);
  const { rive, RiveComponent } = useRive({
    src: "/rive/cta_1008.riv",
    stateMachines: ["cta-states"],
    automaticallyHandleEvents: true,
    isTouchScrollEnabled:true,
    autoplay: true,
    layout: new Layout({
      fit: Fit.Contain,
      alignment: Alignment.Center,
    }),
  });

  const baseState = useStateMachineInput(rive, "cta-states", "state");
  const upload = useStateMachineInput(rive, "cta-states", "upload");
  const uploadError = useStateMachineInput(rive, "cta-states", "upload_error");


  const stopUpload = () => {
    if (upload?.value && baseState?.value) {
      upload.value = false;
      baseState.value = 1;
    }
  };

  const setErrorRive = (message:string) => {
    if (baseState && upload && uploadError) {
      rive?.setTextRunValue("errorRunDescription",message)
      baseState.value = 2;
      upload.value = true;
      uploadError.value = true;
    }
  };

  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length === 0) {
        setErrorRive("File too large!");
      }
    },
    onFileDialogCancel: () => {
      stopUpload();
    },
    noClick: true,
    noKeyboard: true,
    multiple: false,
    maxSize: 10 * 1024 * 1024,
    accept: {
      "application/x-latex": [".latex"],
      "text/x-tex": [".tex", ".ltx", ".sty", ".cls"],
      "application/pdf": [".pdf"],
      "application/msword": [".doc"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
      "application/vnd.ms-excel": [".xls"],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"],
      "application/vnd.ms-powerpoint": [".ppt"],
      "application/vnd.openxmlformats-officedocument.presentationml.presentation": [".pptx"],
      "text/plain": [".txt"],
      "text/html": [".html", ".htm"],
    },
  });

  const [state, setState] = useState<{
    isFileUploadLoading: boolean;
    isPollingActive: boolean;
    isFileUploadDone: boolean;
    isFileUploadSuccess: boolean;
    fileUploadState: "SUCCESSS" | "ERROR" | "NOT_START" | "LOADING";
    fileUploadResponse: TrialFileUpload | null;
    fileProcessingState: number;
    pollErrorCount: number;
    uploadingStateText: string;
    distinctIdCache: string;
  }>({
    isFileUploadLoading: false,
    isPollingActive: false,
    isFileUploadDone: false,
    isFileUploadSuccess: false,
    fileUploadState: "NOT_START",
    fileUploadResponse: null,
    fileProcessingState: 0,
    pollErrorCount: 0,
    uploadingStateText: "Uploading",
    distinctIdCache: "",
  });

  useEffect(() => {
    rive?.setTextRunValue("errorRunDescription","Hello there")
    if (state.fileProcessingState)
      rive?.setNumberStateAtPath("upload_percentage", state.fileProcessingState, "loading");
  }, [rive, state.fileProcessingState]);

  const toggleUpload = useCallback(() => {
    if (upload) {
      if (!upload.value) open();
      upload.value = !upload.value;
    }
  }, [upload, open]);


  useInterval(
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    async () => {
      if (state.fileUploadResponse?.temp_document_id) {
        try {
          const res: ProjectDocumentRedisTicker = await getRequest<ProjectDocumentRedisTicker>(
            `/api/trial/fds-progress?id=${state.fileUploadResponse.temp_document_id}`,
            { next: { revalidate: 0 }, cache: "no-cache" },
          );
          const processingPercentage =
            ((toPercentageValue(res) ?? 0) / TOTAL_PROGRESS) * REST_OF_PROGRESS + MOCK_UPLOAD_LIMIT;

          setState((prev) => ({
            ...prev,
            pollErrorCount: 0,
            fileProcessingState: processingPercentage,
            uploadingStateText: processingPercentage >= 50 ? "Summarizing" : "Processing",
          }));

          if (isProcessingDone(res)) {
            setState((prev) => ({ ...prev, pollErrorCount: 0, fileProcessingState: 100 }));
            const mixpanelId = state.distinctIdCache;
            window.location.replace(
              `${QUINO_APP_URL}/temporary/documents/all/preview?document=${res.documentId}&note-tab=document-summary${mixpanelId ? `&${QUERY_PARAMS.MIXPANEL_DISTINCT_ID}=${mixpanelId}` : ""}`,
            );
          }
          if (res.errorMessageDuringDocumentProcessing) {
            setErrorRive("Processing error");
            setState((prev) => ({ ...prev, pollErrorCount: 0, fileUploadState: "ERROR", fileProcessingState: 0 }));
          }
        } catch (e) {
          if (state.pollErrorCount < POLL_ERROR_LIMIT)
            setState((prev) => ({ ...prev, pollErrorCount: prev.pollErrorCount + 1 }));
          else {
            setErrorRive("Server failed");
            setState((prev) => ({ ...prev, fileProcessingState: 0, fileUploadState: "ERROR" }));
          }
        }
      }
    },
    state.isPollingActive && state.fileUploadState === "LOADING" ? 2000 : null,
  );

  const uploadFile = async (file: File) => {
    try {
      const formData = new FormData();
      formData.append(UPLOAD_FILE, file);
      setState((prev) => ({
        ...prev,
        isFileUploadLoading: true,
        isPollingActive: false,
        fileUploadState: "LOADING",
        fileUploadResponse: null,
        pollErrorCount: 0,
        uploadingStateText: "Uploading",
        fileProcessingState: 0,
        distinctIdCache: getMixpanelDistinctId(),
      }));
      const mockUpload = setInterval(() => {
        setState((prevState) => ({
          ...prevState,
          fileProcessingState: Math.min(prevState.fileProcessingState + 0.5, MOCK_UPLOAD_LIMIT),
        }));
      }, 100);
      const response = await postRequestForFile<TrialFileUpload>("/api/trial/upload-document", formData);
      mixpanelTrack("prereg_fds_document_upload", {
        success: true,
        document_type: file.type,
        document_size_in_mb: file.size / (1024 * 1024),
      });
      clearTimeout(mockUpload);

      setState((prev) => ({
        ...prev,
        isFileUploadLoading: false,
        isPollingActive: true,
        fileUploadResponse: response,
        uploadingStateText: "Processing",
        fileProcessingState: MOCK_UPLOAD_LIMIT,
      }));
    } catch {
      setErrorRive("Upload error");
      setState((prev) => ({
        ...prev,
        isFileUploadLoading: false,
        fileUploadState: "ERROR",
        isPollingActive: false,
        fileUploadResponse: null,
      }));
    }
  };

  useEffect(() => {
    if (acceptedFiles.length > 0) {
      void uploadFile(acceptedFiles[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptedFiles]);

  useEffect(() => {
    if (rive) {
      rive.on(EventType.RiveEvent, (event) => {
        const _data = event.data as unknown as RiveEventPayload;
        switch (_data?.name) {
          case "uploadClicked":
            toggleUpload();
            break;
        }
      });
    }
    return () => {
      rive?.removeAllRiveEventListeners();
    };
  }, [rive, toggleUpload]);

  // console.log(baseState)
  // console.log(upload)
  // console.log(uploadProgress)

  return (
    <section
      id="rive-hero-root"
      {...getRootProps()}
      className="container relative  mx-auto hidden h-32 cursor-pointer items-center justify-center md:flex"
    >
      <section ref={ref} className="h-full w-full">
        <input
          {...getInputProps({
            multiple: false,
          })}
        />
        <RiveComponent />
      </section>
    </section>
  );
};

export default LandingCta;
