import { CheckMarkIcon, NotIncludedIcon } from "@/components/icons";

import { type CleanStripePlansResponse, type PlanDescription } from "@/types";

const FeatureList = ({
  stripePlans,
  currentPlanDescription,
}: {
  stripePlans: CleanStripePlansResponse | undefined;
  currentPlanDescription: PlanDescription;
}) => {
  const newPlanFeatures = stripePlans?.find(
    (featurePlan) => featurePlan.firebaseRole === currentPlanDescription.firebaseRole,
  );

  return (
    <div className="flex flex-col gap-3 flex-wrap h-full md:max-h-[181px] lg:max-h-[238px] xl:max-h-[181px] ">
      {newPlanFeatures?.features.map((feature, index) => (
        <div className="flex items-center gap-3 sm:w-1/2" key={`${currentPlanDescription.name} - ${index}`}>
          <div>{feature.included ? <CheckMarkIcon /> : <NotIncludedIcon />}</div>
          <p className="text-primary-onBg data-[state=false]:text-tertiary-onBg text-sm" data-state={feature.included}>
            {feature.featureDescription}
          </p>
        </div>
      ))}
    </div>
  );
};
export default FeatureList;
