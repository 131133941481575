"use client";
import { useMemo, useState } from "react";

import type { CleanStripePlansResponse, PlanDescription, StripePlanPeriod } from "@/types";
import { cn } from "@/lib/utils";
import PlanCard from "@/components/plans/subscriptions/plan-card";

import Link from "next/link";
import { EnvelopeIcon, Spinner } from "@/components/icons";
import { PAGE_IDS } from "@/constants/landing";
import SquircleWrapper from "@/components/landing/squircle-wrapper";
import { Button } from "@/components/ui/button";

const SubscriptionPlans = ({
  stripePlans,
  headerClassName,
}: {
  stripePlans: CleanStripePlansResponse;
  headerClassName?: string;
}) => {
  const [planPeriod, setPlanPeriod] = useState<StripePlanPeriod>("yearly");

  const mappedPlans = useMemo<PlanDescription[]>(() => {
    if (!stripePlans) return [];
    return stripePlans
      .filter((plan) => plan.type === planPeriod || plan.firebaseRole === "free" || plan.firebaseRole === "enterprise")
      .map((plan) => {
        const res: PlanDescription = {
          firebaseRole: plan.firebaseRole,
          monthlyPrice:
            plan.type === "yearly"
              ? stripePlans.find((item) => {
                  return item.firebaseRole.startsWith("pro") && item.type === "monthly";
                })?.monthlyPrice ?? 0
              : plan.monthlyPrice,
          highlighted: plan.firebaseRole.startsWith("pro"),
          showArrow: plan.firebaseRole.startsWith("pro"),
          price: plan.monthlyPrice,
          name: plan.name,
          priceId: plan.priceId,
          title: plan.name,
          subTitle: plan?.subtitle ?? "Boost your study sessions",
          description:
            plan.firebaseRole === "enterprise"
              ? "Annual billing"
              : plan.type === "yearly"
                ? "Per month, billed yearly"
                : "Per month",
        };
        return res;
      });
  }, [planPeriod, stripePlans]);

  return (
    <section className="w-full">
      <div className="mx-auto max-w-[1180px] px-4 pb-20 sm:px-6 md:py-20">
        <div className={cn("flex flex-col items-center gap-8 lg:gap-24", headerClassName)}>
          <h2 id={PAGE_IDS.SUBSCRIPTION_PLANS} className="text-center text-app-h2 text-primary-on-bg sm:text-app-h1">
            Prepare for your exams with
            <br /> an unlimited subscription
          </h2>
          <div className="mb-10 lg:mb-16 rounded-[16px] shadow-subscription-pan-period">
          <SquircleWrapper cornerRadius={16}>
            <div className=" flex items-center gap-1 rounded-[16px] bg-white p-1   ">
              <SquircleWrapper cornerRadius={8}>
                <button
                  data-state={planPeriod}
                  onClick={() => setPlanPeriod("yearly")}
                  className="flex items-center gap-2 rounded-[12px] px-8 py-3 text-label-button-2 text-primary-on-bg/50 data-[state=yearly]:bg-tertiary-container-hover data-[state=yearly]:text-primary-on-bg"
                >
                  Yearly
                  <div className="rounded-full bg-primitive-orange-600 px-1.5 py-1.5">
                    <p className="text-app-label-l4 text-white ">%</p>
                  </div>
                </button>
              </SquircleWrapper>
              <SquircleWrapper cornerRadius={8}>
                <button
                  data-state={planPeriod}
                  onClick={() => setPlanPeriod("monthly")}
                  className="gap-2 rounded-[12px] px-8 py-4 text-label-button-2 text-primary-on-bg/50 data-[state=monthly]:bg-tertiary-container-hover data-[state=monthly]:text-primary-on-bg"
                >
                  Monthly
                </button>
              </SquircleWrapper>
            </div>
          </SquircleWrapper>
          </div>
        </div>
        <div className="flex flex-col gap-10 lg:gap-16 ">
          <div className="flex w-full flex-col justify-center gap-10 lg:flex-row">
            {mappedPlans.length === 0 && <Spinner className="h-20 w-20" />}
            {mappedPlans.slice(0, 2).map((item) => (
              <PlanCard
                highlighted={item.highlighted}
                key={item.priceId}
                period={planPeriod}
                plan={item}
                showArrow={item.showArrow}
                stripePlans={stripePlans}
              />
            ))}
          </div>
          <SquircleWrapper cornerRadius={32}>
            <div className="flex flex-col items-center justify-between gap-6 rounded-[32px] bg-primitive-blue-100 p-8 sm:flex-row sm:gap-0">
              <h4 className="text-center text-app-h5 text-primary-on-bg sm:text-left sm:text-app-h4">
                Are you representing an enterprise or institution?
              </h4>
              <Link href="mailto:hello@quino.ai">
                <SquircleWrapper cornerRadius={8}>
                  <Button className="flex items-center gap-2 rounded-[12px] bg-primitive-blue-600 px-6 py-5 text-white hover:bg-primitive-blue-600/90">
                    Contact us
                    <EnvelopeIcon />
                  </Button>
                </SquircleWrapper>
              </Link>
            </div>
          </SquircleWrapper>
        </div>
      </div>
    </section>
  );
};
export default SubscriptionPlans;
