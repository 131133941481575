import { type ElementRef, type SVGProps } from "react";



const PlusIcon = (props: SVGProps<ElementRef<"svg">>) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" width="32" height="33" viewBox="0 0 32 33" fill="none">
    <path
      d="M14.6667 27.6144C14.6667 27.968 14.8071 28.3072 15.0572 28.5572C15.3072 28.8073 15.6464 28.9478 16 28.9478C16.3536 28.9478 16.6928 28.8073 16.9428 28.5572C17.1929 28.3072 17.3333 27.968 17.3333 27.6144V18.2811H26.6667C27.0203 18.2811 27.3594 18.1406 27.6095 17.8906C27.8595 17.6405 28 17.3014 28 16.9478C28 16.5941 27.8595 16.255 27.6095 16.0049C27.3594 15.7549 27.0203 15.6144 26.6667 15.6144H17.3333V6.28109C17.3333 5.92747 17.1929 5.58833 16.9428 5.33828C16.6928 5.08823 16.3536 4.94775 16 4.94775C15.6464 4.94775 15.3072 5.08823 15.0572 5.33828C14.8071 5.58833 14.6667 5.92747 14.6667 6.28109V15.6144H5.33333C4.97971 15.6144 4.64057 15.7549 4.39052 16.0049C4.14048 16.255 4 16.5941 4 16.9478C4 17.3014 4.14048 17.6405 4.39052 17.8906C4.64057 18.1406 4.97971 18.2811 5.33333 18.2811H14.6667V27.6144Z"
      fill="#A788DD"
    />
  </svg>
);

const MinusIcon = (props: SVGProps<ElementRef<"svg">>) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" width="32" height="33" viewBox="0 0 32 33" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 16.3437C4 15.9901 4.14048 15.6509 4.39052 15.4009C4.64057 15.1508 4.97971 15.0103 5.33333 15.0103H26.6667C27.0203 15.0103 27.3594 15.1508 27.6095 15.4009C27.8595 15.6509 28 15.9901 28 16.3437C28 16.6973 27.8595 17.0364 27.6095 17.2865C27.3594 17.5365 27.0203 17.677 26.6667 17.677H5.33333C4.97971 17.677 4.64057 17.5365 4.39052 17.2865C4.14048 17.0364 4 16.6973 4 16.3437Z"
      fill="#A788DD"
    />
  </svg>
);


export {PlusIcon, MinusIcon}